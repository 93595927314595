import React from 'react';

export default function Telephone(){
    return (
 

<a href="tel:+33 1 85 53 82 73">
    <div className="flex flex-col items-center bg-[#F05907] text-white justify-around p-2 h-10 rounded-md">
        <div>
           <span>01&nbsp;85&nbsp;53&nbsp;82&nbsp;73</span>
        </div>
       
    </div>

</a>


    )}

