import React from 'react';

export default function Wave(){
  return (

<svg
viewBox="0 0 500 150"
preserveAspectRatio="none"
className='h-full w-[100vw]'
style={{stroke: "none", fill: "#001A5B"}}
><path
  d="M-8.74,12.33 C124.43,-36.02 218.11,99.17 513.25,-4.43 L500.00,150.00 L0.00,150.00 Z"
  ></path></svg>

  )}

 