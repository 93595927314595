import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';


export default function Social(){
  return (

<div className="flex gap-1 relative md:top-15 md:left-9">
  <a href="https://www.facebook.com/IPNOFL" className='flex justify-start'>
    <StaticImage src="../images/index/facebook.png" alt='facebook logo' className='w-[60%] md:w-full' width={50}/>
  </a>
  <a href="https://www.instagram.com/lefeuvre.francoise/" className='flex justify-end'>
    <StaticImage src="../images/index/instagram.png" alt='instagram logo' className='w-[60%] md:w-full' width={50} />
  </a>
</div>

  )}

