import React from 'react';

import RdvsButtonFooter from "./widgets/RdvsButtonFooter";
import Righty from "./widgets/Righty";
import Social from "./Social";
import Wave from "./widgets/Wave";
import TelephoneSticky from "./widgets/TelephoneSticky";
import * as styles from "./footer.module.css"
import clsx from 'clsx';
import { Link, withPrefix } from 'gatsby';
import mentionsLegales from "../static/mentionslegales.pdf"


export function Footer() {
  return (
    <div className={styles.myfooter}>
    
        <div className={styles.navfooter}>
          <div className={styles.btnrdvs}>
            <RdvsButtonFooter />
          </div>
          <div className={styles.links}>
            <Link to="/">ACCUEIL</Link>
            <Link to="/apropos">À PROPOS</Link>
            <Link to="/seances">SÉANCES</Link>
            <Link to="/tarifs">TARIFS</Link>
            <Link to="/contacts">CONTACTS</Link>
            <Link to="/blog">BLOG</Link>
          </div>
          <div className="text-center !underline">
            <a href={withPrefix(mentionsLegales)}>MENTIONS LÉGALES</a>
          </div>

          <div className={styles.adresse}>
            <Link to="https://www.google.com/maps/place/Fran%C3%A7oise+Lefeuvre+-+Praticienne+en+hypnose+ericksonienne+-+Paris+20/@48.8578636,2.3891272,14.74z/data=!3m1!5s0x47e67209c5ba51e1:0xec23dc7d96a8fe46!4m14!1m7!3m6!1s0x47e67303acd3e371:0xbeb2ab80431345b1!2s%F0%9F%8F%BB%E2%80%8D+%EF%B8%8F+Fran%C3%A7oise+Lefeuvre+-+Praticienne+en+hypnose+ericksonienne+-+Paris+11!8m2!3d48.8546266!4d2.3816881!16s%2Fg%2F11h_wy51b8!3m5!1s0x47e66dca4a7a52f7:0x8f4cb19e271b8d1a!8m2!3d48.8574478!4d2.4000833!16s%2Fg%2F11s8dm_r2j">
              Cabinet paramédical au 6 rue de Fontarabie 75020
            </Link>
          </div>
          <div className={styles.siret}>
            Francoise Lefeuvre - Siret 795 241 546 00014
          </div>
        </div>
      


      <div className={styles.social}>
        <Social />
      </div>

 
      <div className='absolute b-2'>
        <TelephoneSticky />
      </div>


      <div className={styles.wave}><Wave /></div>
    </div>

  )
}

