
import React from 'react';

export default function RdvsButton(){
    return (

<a href="https://www.resalib.fr/praticien/37328-francoise-lefeuvre-praticien-en-hypnose-paris#newrdvmodal" target="_blank">
    <div className="flex flex-col items-center bg-[#001A5B] text-white justify-around text-sm h-10 gap-0 px-3 rounded-md
">
        <div className='relative t-2'
       >
            PRENDRE
        </div>
        <div className='relative -t-2'>
            RENDEZ-VOUS
        </div>
    </div>
</a>
    )
}


