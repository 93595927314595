exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-apropos-tsx": () => import("./../../../src/pages/apropos.tsx" /* webpackChunkName: "component---src-pages-apropos-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-seances-tsx": () => import("./../../../src/pages/seances.tsx" /* webpackChunkName: "component---src-pages-seances-tsx" */),
  "component---src-pages-tarifs-tsx": () => import("./../../../src/pages/tarifs.tsx" /* webpackChunkName: "component---src-pages-tarifs-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}

