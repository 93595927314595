
import React, { useState } from 'react';

import RdvsButton from "./widgets/RdvsButton";
import Telephone from "./widgets/Telephone";
import * as styles from "./navbar.module.css";
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { globalHistory } from "@reach/router"
import { Link } from 'gatsby';

export function Navbar({
    href,
    children,
  }: {
    href: string
    children: React.ReactNode
  }) {

    const [mobileOpen, setMobileOpen] = useState(false);
    const pathname = globalHistory.location.pathname;

return (
<header className="relative flex items-center bg-white justify-center align-middle max-w-[1280px]">
    <nav className="fixed h-12 flex w-[100%] justify-around items-center sm:top-[-60px] md:h-[60px]  z-10">
        <ul id="menu" className={clsx( !mobileOpen && 'invisible -mt-[850px]', mobileOpen && 'visible mt-[487px]', 'duration-500 items-center bg-white flex relative w-full flex-col gap-2 justify-around mt-[-850px] max-w-[1280px] md:visible md:mt-[0]  md:flex-row mx-auto md:w-[100%]')}>
            <li > 
                <Link data-name="accueil" to="/" className={clsx(pathname === '/' && '!underline !decoration-[orangered] !underline-offset-[5px] !decoration-[0.2rem]')}>ACCUEIL</Link>
            </li>
            <li>
                <Link data-name="apropos" to="/apropos" className={clsx(pathname === '/apropos/' && '!underline !decoration-[orangered] !underline-offset-[5px] !decoration-[0.2rem]')}>À PROPOS</Link>
            </li>
            <li>
                <Link data-name="seances" to="/seances" className={clsx(pathname === '/seances/' && '!underline !decoration-[orangered] !underline-offset-[5px] !decoration-[0.2rem]')}>SÉANCES</Link>  
            </li>    
            <li>
                <Link data-name="tarifs" to="/tarifs" className={clsx(pathname === '/tarifs/' && '!underline !decoration-[orangered] !underline-offset-[5px] !decoration-[0.2rem]')}>TARIFS</Link> 
            </li>   
            <li>
                <Link data-name="contacts" to="/contacts" className={clsx(pathname === '/contacts/' && '!underline !decoration-[orangered] !underline-offset-[5px] !decoration-[0.2rem]')}>CONTACTS</Link> 
            </li>
            <li>
                <Link data-name="blog" to="/blog" className={clsx(pathname === '/blog/' && '!underline !decoration-[orangered] !underline-offset-[5px] !decoration-[0.2rem]')}>BLOG</Link> 
            </li>
            <li>
               <Telephone />
            </li>
            <li className={styles.rdvs}>
                <RdvsButton></RdvsButton>
            </li>    
                                                          
        </ul>
    </nav>
    <div id="togglemenu" className= "md:invisible font-bold font text-lg mt-2 cursor-pointer fixed top-2 right-2 z-20">
            <Bars3Icon className={clsx(mobileOpen && 'hidden', 'w-8 text-[#F05907]')} onClick={() => setMobileOpen(true)}></Bars3Icon>
            <XMarkIcon className={clsx(!mobileOpen && 'hidden', 'w-8 text-[#F05907]')} onClick={() => setMobileOpen(false)}></XMarkIcon>
    </div>

</header>
)
}

