import React from 'react';

export default function TelephoneSticky(){
  return (

<a href="tel:+33 1 85 53 82 73">
  <div className="fixed bottom-10 right-10 flex items-center bg-[#f05907] text-white justify-around  p-2 rounded-lg z-100">
    <div>
      <span>01&nbsp;85&nbsp;53&nbsp;82&nbsp;73</span>
    </div>
  </div>
</a>

  )}

