import "./src/styles/global.css"
import React from 'react';
import Layout from './src/components/Layout';
import ReactDOM from "react-dom/client";

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}
export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};