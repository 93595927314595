module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-apollo@4.0.3_@apollo+client@3.11.9_gatsby@5.14.0/node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"https://hypnoseparisblog.live-website.com/graphql"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-catch-links@5.14.0_gatsby@5.14.0/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-source-wordpress@7.14.0_gatsby-plugin-image@3.14.0_gatsby-plugin-sharp@5.14.0_gatsby-t_q7kyjlmbvbrzarsootdxpx5dtm/node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://hypnoseparisblog.live-website.com/graphql","verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.0_graphql@16.9.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favicon-32x32.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"80a854b1e35643600435cb64278572d4"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-tagmanager@5.14.0_gatsby@5.14.0_react-dom@18.3.1_react@18.3.1/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@5.14.0_gatsby@5.14.0_react-dom@18.3.1_react@18.3.1/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-WP6XX5L4TN"],"gtagConfig":{}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.0_babel-eslint@10.1.0_react-dom@18.3.1_react@18.3.1_typescript@5.6.3/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
