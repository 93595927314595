import React from 'react';


export default function RdvsButtonFooter(){
    return (


<a href="https://www.resalib.fr/praticien/37328-francoise-lefeuvre-praticien-en-hypnose-paris#newrdvmodal" target="_blank">
    <div className="text-center bg-[#F05907] w-52 px-2 py-1 mx-auto">
        <div>
            PRENDRE RENDEZ-VOUS
        </div>
    </div>
</a>

    )
}

