// extracted by mini-css-extract-plugin
export var adresse = "footer-module--adresse--0d80d";
export var foliage = "footer-module--foliage--b52a6";
export var left = "footer-module--left--6dcac";
export var links = "footer-module--links--c97aa";
export var myfooter = "footer-module--myfooter--27483";
export var navfooter = "footer-module--navfooter--4dc4d";
export var right = "footer-module--right--91fe4";
export var siret = "footer-module--siret--f9c53";
export var social = "footer-module--social--912f1";
export var wave = "footer-module--wave--7852c";