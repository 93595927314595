
import React from 'react';
import * as styles from './modalCertif.module.css';

export default function ModalCertif(){
  return (
<div id="modal" className={styles.modal}>
  <img className={styles.modalContent} id="imgmodal" />
</div>

  )}


  /*


<script>
  const modal = document.getElementById("modal") as HTMLElement;
  const modalImg = document.getElementById("imgmodal") as HTMLImageElement;

  modal.onclick = function () {
    const self = this as HTMLElement;
    self.style.display = "none";
  };

  function getPathImage(src) {
    return `/assets/index/${src}`;
  }

  globalThis.openModal = function (src) {
    modal.style.display = "block";
    modalImg.src = getPathImage(src);
  };
</script>
*/