
import React from "react";
import { Navbar } from "./Navbar";
import { Footer } from "./Footer";
import ModalCertif from "./ModalCertif";
import * as styles from "./layout.module.css";
import { Script } from "gatsby";


export default function Layout({children}){
    return (
        <html lang="fr">
        <head>
          <meta charSet="UTF-8" />
          <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
 
          <title>Hypnose Paris</title>
        </head>
        <body>
          <div className="max-w-[1280px] mx-auto"
          >
            <Navbar />
          </div>
      
          <div className={styles.maincontent}>
            {children}
          </div>
      
          <Footer />
                
          <ModalCertif />

          <div className="elfsight-app-d1095e31-2a31-47b0-9022-67301b69e2cf" data-elfsight-app-lazy></div>

        </body>
        <Script src="https://static.elfsight.com/platform/platform.js" data-use-service-core defer></Script>
         
      </html>
      

    )
}


